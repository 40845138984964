import React from 'react';

import HorizontalNav1 from '../components/horizontal-navs/HorizontalNav1';
import HeadersCustom1 from '../components/headers/HeadersCustom1';
import Footer4 from '../components/footers/Footer4';
import SignIn2 from '../components/sign-in/SignIn2';

const meta = {};

export default function SignIn() {
  console.log("SIGNIN")
  return (
    <React.Fragment>
      {/* <HorizontalNav1 content={null} /> */}

      {/* <HeadersCustom1 /> */}

      <SignIn2 content={null} />

      {/* <Footer4 content={null} /> */}

      
    </React.Fragment>
  );
}

