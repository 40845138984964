import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignInPage from './pages/SignIn.js';
import PatientPage from './pages/Patient.js';
import AssessmentPage from './pages/Assessment.js';
import ResultsPage from './pages/Results.js';


const router = createBrowserRouter([
  {
    path: "/",
    element: <SignInPage />,
  },
  {
    path: "patient",
    element: <PatientPage />,
  },
  {
    path: "assessment/:assessment_id",
    element: <AssessmentPage />,
  },
  {
    path: "result/:assessment_id",
    element: <ResultsPage />,
  },
]);


export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
