import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { emphasize, fade, darken, lighten } from '@material-ui/core/styles/colorManipulator';

import clsx from 'clsx';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  box: {
    paddingTop: theme.spacing(8),
    paddingBottom:  theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom:  theme.spacing(2),
    }
  },
  header: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 0
    }
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
  img: {
    height: 768
  },
}
));

export default function Component(props) {
  const classes = useStyles();

  return (
<section className={classes.section} style={{ backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")' }}>
  <Container maxWidth="lg">
    <Box className={classes.box}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box display="flex" height="100%">
            <Box my="auto" width="100%">
              <Container maxWidth="xs" className={classes.header}>
                <Typography variant="h2" component="h2" gutterBottom={true}>
                  <Typography variant="h2" component="span" color="primary">Maestro </Typography>
                  <Typography variant="h2" component="span">TAG LINE</Typography>
                </Typography>
              </Container>
              <Container maxWidth="sm">
                <Typography variant="subtitle1" color="textSecondary">
                  PARAGRAPH.
                </Typography>
                <Box mt={3}>
                  <Button variant="contained" color="secondary" className={classes.primaryAction}>Action</Button>
                  <Link href="#">Link Default</Link>
                </Box>
              </Container>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <Container maxWidth="xs">
              <Box py={16}>
                <Card variant="outlined">
                  <CardContent>
                    <Box display="flex" mt={3}>
                      <Box mx="auto">
                        <Avatar className={classes.iconWrapper} variant="rounded">
                          <AssignmentIndIcon />
                        </Avatar>
                      </Box>
                    </Box>
                    <Box mt={2} px={4}>
                      <Typography variant="h5" component="h3" align="center" gutterBottom={true}>Lorem ipsum dolor sit amet</Typography>
                      <Typography variant="body2" component="p" color="textSecondary" align="center">Suspendisse aliquam tellus ante, porttitor mattis diam eleifend quis.</Typography>
                      <Box my={3}>
                        <form noValidate>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField variant="outlined" required fullWidth size="small" name="email" label="E-mail address" />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField variant="outlined" required fullWidth size="small" type="password" name="password" label="Password" />
                            </Grid>
                            <Grid item xs={12}>
                              <Box alignItems="center" justifyContent="space-between" className={classes.actions}>
                                <Link href="#" color="textSecondary">Forgot your password?</Link>
                                <Button type="submit" variant="contained" color="secondary" size="large" className={classes.primaryAction}>
                                  Sign in
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </form>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Container>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </Container>
</section>
  );
}