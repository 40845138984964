import React from 'react';

import HorizontalNav1 from '../components/horizontal-navs/HorizontalNav1';
import HeadersCustom1 from '../components/headers/HeadersCustom1';
import Footer4 from '../components/footers/Footer4';
import PatientForm from '../components/PatientForm';

const meta = {};

export default function Patient() {
  return (
    <React.Fragment>
      {/* <HorizontalNav1 content={null} />

      <HeadersCustom1 /> */}

      <PatientForm content={null} />

      {/* <Footer4 content={null} /> */}


    </React.Fragment>
  );
}