import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { orange } from '@material-ui/core/colors';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Image } from '@material-ui/icons';

const API = process.env.REACT_APP_API_URL

const useStyles = makeStyles((theme) => ({
  section: {
    // backgroundImage: 'url("nereus-assets/img/bg/pattern2.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // backgroundColor: theme.palette.primary.dark,
    // height: '100%'
  },
  iconWrapper: {
    backgroundColor: orange[100],
    color: theme.palette.secondary.main
  },
  actions: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  primaryAction: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
      width: 'auto'
    }
  }
}));

export default function Form(props) {
  const classes = useStyles();

  const content = {
    'header': 'Login to Maestro',
    'description': 'Quick Autism Assessments are only a click away.',
    'primary-action': 'Sign in',
    'secondary-action': 'Forgot your password?',
    ...props.content
  };

  const navigate = useNavigate();

  const LoginUser = async (e) => {
    e.preventDefault();
    const { email, password } = e.target.elements
    const user = { email: email.value, password: password.value }
    try {
      const res = await fetch(`${API}/auth/login`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
      const { token } = await res.json()
      console.log(token)
      if(token){
        navigate('/patient', { state: { user_id: email.value, token } });
      }
    } catch (error) {
      console.error(error)
    }
    
  }

  return (
    <section className={classes.section}>
      <Container maxWidth="xs">
        <Box py={16}>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" mt={3}>
                <Box mx="auto">
                  <Avatar variant='square' src="/icon.jpg" sx={{ width: 24, height: 24 }} />
                </Box>
              </Box>
              <Box mt={2} px={4}>
                <Typography variant="h5" component="h3" align="center" gutterBottom={true}>{content['header']}</Typography>
                <Typography variant="body2" component="p" color="textSecondary" align="center">{content['description']}</Typography>
                <Box my={3}>
                  <form noValidate onSubmit={LoginUser}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth size="small" name="email" label="E-mail address" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField variant="outlined" required fullWidth size="small" type="password" name="password" label="Password" />
                      </Grid>
                      <Grid item xs={12}>
                        <Box alignItems="center" justifyContent="space-between" className={classes.actions}>
                          {/* <Link href="#" color="textSecondary">{content['secondary-action']}</Link> */}
                          <Button type="submit" variant="contained" color="secondary" size="large" className={classes.primaryAction}>
                            {content['primary-action']}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </section>
  );
}