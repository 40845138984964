import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import { TextField, MenuItem, Button } from '@material-ui/core'
import RateReviewIcon from '@material-ui/icons/RateReview';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core'

const API = process.env.REACT_APP_API_URL

const REQUIRED_FIELDS = [
    "title",
    "first_name",
    "last_name",
    "name",
    "gender",
    "address",
    "postcode",
    "nhs_number"
]

const OPTIONAL_FIELDS = [
    "school_name",
    "school_year"
]

const FIELDS = [
    ...REQUIRED_FIELDS,
    ...OPTIONAL_FIELDS
]

const ETHNICITIES = [
    "African",
    "Carribean",
    "Asian",
    "Middle Eastern",
    "Latinx",
    "Indigenous",
    "Causcasian",
    "Other"
]

const MARITAL_STATUS = ["Married", "Single", "Other"]

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch'
        }
    },
    button: {
        margin: theme.spacing(1)
    }
}));

async function createAssessment(token, user_id) {
    console.info("CREATING ASSESSMENT")
    const res = await fetch(`${API}/assessment/create`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ user_id })
    })
    return res.json()
}

function PatientForm() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { state } = useLocation();

    if (!state?.token) {
        navigate(`/`)
    }

    async function submitForm(e) {
        e.preventDefault();
        const { elements } = e.target
        const report = {}
        const patient = {}
        for (const field of FIELDS) {
            patient[field] = elements[field].value
            report[`patient_${field}`] = elements[field].value
        }
        const assessment = await createAssessment(state.token, state.user_id)
        navigate(`/assessment/${assessment.assessment_id}`, { state: { ...state, assessment, patient, report } })
    }

    return (
        <CSSTransitionGroup
            className="container result"
            component="div"
            transitionName="fade"
            transitionEnterTimeout={800}
            transitionLeaveTimeout={500}
            transitionAppear
            transitionAppearTimeout={500}>
            <Typography variant="subtitle1" gutterBottom className="App-intro">
                Person being assessed's details
            </Typography>
            <form
                noValidate
                className={classes.root}
                autoComplete="off"
                onSubmit={submitForm}
                id="PatientForm">
                <TextField id="title" variant="filled" label="Title" />
                <TextField id="first_name" variant="filled" label="First Name" />
                <TextField id="last_name" variant="filled" label="Last Name" />
                <TextField id="name" variant="filled" label="Preferred Name" />
                <TextField id="gender" variant="filled" label="Gender" />
                <TextField id="address" variant="filled" label="Home Address" />
                <TextField id="postcode" variant="filled" label="Postcode" />
                <TextField id="nhs_number" variant="filled" label="NHS Number" />
                <TextField id="ethnicity" select label="Ethnicity" variant="filled">
                    {ETHNICITIES.map((option) => (
                        <MenuItem key={option} value={option || ''}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField id="marital_status" select label="Marital Status" variant="filled">
                    {MARITAL_STATUS.map((option) => (
                        <MenuItem key={option} value={option || ''}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    id="school_name"
                    variant="filled"
                    label="School Name (if applicable)" />
                <TextField
                    id="school_year"
                    variant="filled"
                    label="School Year (if applicable)" />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={< RateReviewIcon />}
                    type="submit">
                    Begin Assessment
                </Button>
            </form>
        </CSSTransitionGroup>
    );
}

export default PatientForm;
