import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core'
import DescriptionIcon from '@material-ui/icons/Description';
import { makeStyles } from '@material-ui/core/styles';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable'
import { TemplateHandler } from 'easy-template-x';

const handler = new TemplateHandler();

function saveFile(filename, blob) {

    // see: https://stackoverflow.com/questions/19327749/javascript-blob-filename-without-link

    // get downloadable url from the blob
    const blobUrl = URL.createObjectURL(blob);

    // create temp link element
    let link = document.createElement("a");
    link.download = filename;
    link.href = blobUrl;

    // use the link to invoke a download
    document.body.appendChild(link);
    link.click();

    // remove the link
    setTimeout(() => {
        link.remove();
        window.URL.revokeObjectURL(blobUrl);
        link = null;
    }, 0);
}

function genReportDoc(report) {
    return async () => {
        console.info("final", report)
        const response = await fetch('/template.docx');
        const templateFile = await response.blob();
        const doc = await handler.process(templateFile, report);
        saveFile(`AutismMapper-${new Date().toISOString()}.docx`, doc)
    }
}

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        "margin-left": 0
    }
}));

// function genTablePDF(rows) {
//     return () => {
//         const doc = new jsPDF()
//         doc.autoTable({
//             head: [
//                 ['Category', 'Sub_Category1', 'Sub_Category2', 'Score']
//             ],
//             body: rows
//         })
//         doc.save(`AutismMapper Breakdown-${new Date().toISOString()}.pdf`)
//     }
// }

function ResultTable(props) {
    const classes = useStyles();
    const { report } = props
    return (
        <React.Fragment>
            {/* <Button
                variant="contained"
                color="primary"
                size="large"
                disabled
                // fullWidth={true}
                className={classes.button}
                startIcon={< DescriptionIcon />}
                onClick={genTablePDF(rows)}>
                Autism Spectrum Breakdown
            </Button> */}
            <Button
                variant="contained"
                color="primary"
                size="large"
                // fullWidth={true}
                className={classes.button}
                startIcon={< DescriptionIcon />}
                onClick={genReportDoc(report)}>
                Written Report
            </Button>
        </React.Fragment>
    )
}

ResultTable.propTypes = {
    rows: PropTypes.array.isRequired,
    report: PropTypes.object.isRequired
};

export default ResultTable;