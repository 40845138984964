import React, { Component } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import Question from './Question';
import QuestionCount from './QuestionCount';
import AnswerOption from './AnswerOption';
import Notes from './Notes'
import QUESTIONS from '../../api/data.json';

const API = process.env.REACT_APP_API_URL

function withHooks(Component) {
  return props => <Component {...props} location={useLocation()} navigate={useNavigate()} />;
}

class Assessment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      counter: 0,
      questionNum: 1,
      question: {},
      answers: [],
      notes: [],
      answer: '',
      result: {},
      report: {},
      ...props.location.state
    }

    this.navigate = props.navigate
  }

  componentDidMount() {
    console.log("DIDMOUNT")
    this.setState({
      question: {
        ...QUESTIONS[0],
        Question: this.addUserDetails(QUESTIONS[0].Question)
      },
      answers: QUESTIONS[0].Answers
    });

    if (!this.state?.token || !this.state?.patient) {
      this.navigate(`/`)
    }
  }

  addUserDetails(text, patient = this.state.patient) {
    return text.replace(/\$NAME/g, patient.name)
  }

  handleAnswerSelected({ answer, notes }) {
    this.setUserResponse(answer, notes)

    if (this.state.questionNum < QUESTIONS.length) {
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      setTimeout(() => this.goToResults(), 300);
    }
  }

  async saveAssessment() {
    const assessmentInfoText = `${this.state.assessment.assessment_id}, Q${this.state.questionNum}/${QUESTIONS.length}`
    console.info(`SAVING ASSESSMENT ${assessmentInfoText}`)
    const done = this.state.questionNum === QUESTIONS.length
    const assessment = { ...this.state.assessment, done, patient: this.state.patient }
    try {
      await fetch(`${API}/assessment/save`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.state.token}`
        },
        body: JSON.stringify(assessment)
      })
    } catch (error) {
      console.error(`FAILED TO SAVE ASSESSMENT ${assessmentInfoText}`)
      console.error(error)
    }
  }

  setUserResponse(answer, notes) {
    this.setState((state, props) => {
      const key = `${state.question.Category}%${state.question.Sub_Category1}%${state.question.Sub_Category2}`
      const question = state.question.QuestionID
      const data = {
        answer: answer.Answer,
        text: answer.Text,
        weight: answer.weight,
        notes: notes,
        category: state.question.Category,
        sub_category1: state.question.Sub_Category1,
        sub_category2: state.question.Sub_Category2
      }
      const report = {
        ...state.report,
        [question]: this.addUserDetails(answer.Text),
        [`${question}_notes`]: notes
      }
      return {
        result: {
          ...state.result,
          [key]: (state.result[key] || 0) + parseInt(answer.Weight)
        },
        report,
        answer: '',
        assessment: {
          ...state.assessment,
          assessment: {
            ...state.assessment.assessment,
            [question]: data
          }
        }
      }
    }, this.saveAssessment)
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionNum = this.state.questionNum + 1;

    const question = {
      ...QUESTIONS[counter],
      Question: this.addUserDetails(QUESTIONS[counter].Question)
    }

    this.setState({ counter: counter, questionNum: questionNum, question, answers: question.Answers, answer: '' });
  }

  goToResults() {
    this.navigate(`/result/${this.state.assessment.assessment_id}`, { state: { ...this.state } })
  }

  renderAnswerOptions() {
    const options = []
    for (const answer of this.state.answers) {
      const update = () => {
        this.setState({ answer })
      }
      update.bind(this)
      options.push(
        <AnswerOption key={answer.Answer}
          answerContent={answer.Answer}
          answerWeight={answer.Weight}
          // answer={this.state.answer}
          questionNum={this.state.questionNum}
          onAnswerSelected={update} />
      )
    }

    return (
      < ul className="answerOptions">
        {options}
      </ul>
    )
  }

  render() {
    return (
      this.state.question && <CSSTransitionGroup
        className="container"
        component="div"
        transitionName="fade"
        transitionEnterTimeout={800}
        transitionLeaveTimeout={500}
        transitionAppear
        transitionAppearTimeout={500}>
        <div key={this.state.questionNum}>
          <QuestionCount counter={this.state.questionNum} total={QUESTIONS.length} />
          <Question content={this.state.question.Question} />
          {!this.state.answer
            ? this.renderAnswerOptions()
            : <Notes
              answer={this.state.answer}
              onAnswerSelected={this.handleAnswerSelected.bind(this)} />}

        </div>
      </CSSTransitionGroup >)
  }
}

export default withHooks(Assessment);
