import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        // width: "100%"
    },
    textbox: {
        marginBottom: theme.spacing(1),
        // width: "100%"
    }
}));

function Notes(props) {
    const classes = useStyles(props)
    const [notes,
        setNotes] = React.useState("")
    const submitQuestion = () => {
        props.onAnswerSelected({answer: props.answer, notes})
        setNotes(null)
    }
    return (
        <div className={classes.root}>
            <TextField
                className={classes.textbox}
                id="notes"
                label="Question Notes"
                multiline
                maxRows={5}
                value={notes}
                fullWidth
                minRows={3}
                onChange={(e) => {
                setNotes(e.target.value)
            }}/>
            <Button
                variant="contained"
                color="primary"
                component="span"
                onClick={submitQuestion}
                fullWidth>
                Next Question
            </Button>
        </div>
    );
}

Notes.propTypes = {
    answer: PropTypes.object.isRequired,
    onAnswerSelected: PropTypes.func.isRequired
};

export default Notes