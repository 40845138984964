import React from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransitionGroup } from 'react-transition-group';
import ResultTable from './ResultTable'
import Report from './Report'
import Diagnosis from './Diagnosis'

function Result() {
  const location = useLocation()
  const { result, report } = location.state
  const rows = []
  for (const categoryNames in result) {
    const score = result[categoryNames]
    const row = categoryNames.split(/%/g)
    row.push(score)
    rows.push(row)
  }
  return (
    <CSSTransitionGroup
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}>
      <Diagnosis quizResult={result} />
      <ResultTable rows={rows} />
      <Report rows={rows} report={report} />
    </CSSTransitionGroup>
  );
}

export default Result;
