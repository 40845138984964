import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme/theme';
import './index.css';

const rootElement = document.querySelector('#root');

ReactDOM.render(
  <App />,
  rootElement
);
